* {
	-webkit-touch-callout: 'none';
}
@font-face {
	font-family: 'RationalText';
	font-style: bold;
	font-display: swap;
	font-weight: 900;
	src: local('RationalText'), local('RationalText-bold'),
		url('../src/assets/fonts/RationalText/RationalDisplay-SemiBold.ttf') format('truetype');
	unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
		U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
	font-family: 'RationalText';
	font-style: medium;
	font-display: swap;
	font-weight: 600;
	src: local('RationalText'), local('RationalText-Medium'),
		url('../src/assets/fonts/RationalText/RationalDisplay-Medium.ttf') format('truetype');
	unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
		U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
	font-family: 'RationalText';
	font-style: normal;
	font-display: swap;
	font-weight: 300;
	src: local('RationalText'), local('RationalText-book'),
		url('../src/assets/fonts/RationalText/RationalDisplay-Book.ttf') format('truetype');
	unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
		U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
	font-family: 'AvenirNextLTPro';
	font-style: normal;
	font-display: swap;
	font-weight: 300;
	src: local('AvenirNextLTPro'), local('AvenirNextLTPro-regular'),
		url('../src/assets/fonts/Avenir/AvenirNextLTPro-Regular.otf') format('opentype');
	unicoderange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
		U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiBottomNavigationAction-label {
	font-size: 0.7rem !important;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: transparent transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 0.2em;
}

*::-webkit-scrollbar-track {
	position: 'absolute';
	background: transparent;
	border-radius: 0.5em;
}

*::-webkit-scrollbar-thumb {
	position: 'absolute';

	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 0.5em;
	border: 0 solid transparent;
}

html {
	height: 100%;
	background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
	overflow: hidden;
}

#stars_1 {
	position: absolute;
	width: 1px;
	height: 1px;
	background: transparent;
	/* animation: animStar 30s linear infinite; */
	box-shadow: 0px 0px #fff, 181px 458px #fff, 8px 764px #fff, 185px 820px #fff, 307px 1809px #fff,
		479px 1435px #fff, 123px 200px #fff, 519px 1045px #fff, 450px 1886px #fff, 317px 883px #fff,
		151px 1431px #fff, 30px 1143px #fff, 521px 1643px #fff, 427px 1372px #fff, 198px 1825px #fff,
		306px 1223px #fff, 324px 407px #fff, 464px 1083px #fff, 550px 1048px #fff, 563px 1232px #fff,
		359px 1486px #fff, 156px 1277px #fff, 275px 1705px #fff, 554px 254px #fff, 513px 76px #fff,
		500px 555px #fff, 78px 1035px #fff, 434px 1775px #fff, 100px 1232px #fff, 88px 1782px #fff,
		579px 772px #fff, 96px 990px #fff, 369px 1938px #fff, 302px 867px #fff, 288px 1933px #fff,
		506px 855px #fff, 101px 1369px #fff, 167px 1662px #fff, 474px 1682px #fff, 434px 1852px #fff,
		306px 1516px #fff, 136px 1868px #fff, 135px 103px #fff, 138px 1113px #fff, 525px 1271px #fff,
		55px 1131px #fff, 18px 1020px #fff, 487px 677px #fff, 13px 1664px #fff, 532px 38px #fff,
		44px 901px #fff, 210px 224px #fff, 425px 482px #fff, 547px 1613px #fff, 192px 701px #fff,
		38px 85px #fff, 370px 383px #fff, 256px 39px #fff, 570px 1987px #fff, 284px 349px #fff,
		594px 503px #fff, 168px 14px #fff, 133px 578px #fff, 290px 574px #fff, 523px 1975px #fff,
		177px 1906px #fff, 16px 532px #fff, 244px 593px #fff, 217px 963px #fff, 148px 968px #fff,
		265px 1707px #fff, 328px 11px #fff, 285px 1751px #fff, 141px 172px #fff, 130px 1419px #fff,
		258px 397px #fff, 416px 235px #fff, 598px 453px #fff, 51px 936px #fff, 55px 1654px #fff,
		419px 1421px #fff, 306px 494px #fff, 584px 1383px #fff, 339px 244px #fff, 450px 533px #fff,
		545px 1980px #fff, 403px 312px #fff, 557px 665px #fff, 291px 1657px #fff, 530px 339px #fff,
		251px 429px #fff, 93px 875px #fff, 197px 1020px #fff, 74px 1047px #fff, 182px 1227px #fff,
		37px 1704px #fff, 459px 819px #fff, 572px 247px #fff, 547px 924px #fff, 484px 1010px #fff,
		116px 1645px #fff, 283px 982px #fff, 521px 1154px #fff, 66px 744px #fff, 578px 700px #fff,
		16px 1780px #fff, 206px 1867px #fff, 73px 1564px #fff, 80px 108px #fff, 67px 841px #fff,
		50px 593px #fff, 309px 1537px #fff, 287px 1001px #fff, 455px 1696px #fff, 380px 1117px #fff,
		252px 385px #fff, 41px 1740px #fff, 374px 1388px #fff, 506px 247px #fff, 398px 258px #fff,
		45px 1313px #fff, 412px 240px #fff, 394px 122px #fff, 345px 1495px #fff, 498px 1970px #fff,
		145px 908px #fff, 556px 396px #fff, 395px 1291px #fff, 197px 68px #fff, 143px 1739px #fff,
		94px 496px #fff, 258px 1560px #fff, 467px 879px #fff, 260px 1674px #fff, 71px 912px #fff,
		22px 272px #fff, 544px 350px #fff, 180px 804px #fff, 137px 289px #fff, 4px 1381px #fff,
		479px 1156px #fff, 3px 630px #fff, 559px 1204px #fff, 367px 725px #fff, 137px 1501px #fff,
		515px 580px #fff, 454px 724px #fff, 28px 35px #fff, 233px 734px #fff, 256px 586px #fff,
		152px 328px #fff, 558px 985px #fff, 388px 210px #fff, 271px 201px #fff, 379px 1327px #fff,
		212px 389px #fff, 274px 1209px #fff, 52px 300px #fff, 270px 1771px #fff, 248px 254px #fff,
		304px 1144px #fff, 542px 1519px #fff, 176px 1844px #fff, 435px 1278px #fff,
		224px 1754px #fff, 94px 480px #fff, 139px 715px #fff, 207px 897px #fff, 329px 186px #fff,
		531px 534px #fff, 215px 683px #fff, 455px 1565px #fff, 19px 1372px #fff, 583px 1605px #fff,
		354px 246px #fff, 523px 109px #fff, 81px 955px #fff, 147px 698px #fff, 176px 660px #fff,
		375px 186px #fff, 330px 1061px #fff, 443px 871px #fff, 528px 1267px #fff, 401px 1726px #fff,
		102px 1058px #fff, 397px 611px #fff, 198px 778px #fff, 228px 1644px #fff, 43px 1097px #fff,
		113px 633px #fff, 301px 535px #fff, 480px 1566px #fff, 301px 1917px #fff, 388px 239px #fff,
		537px 655px #fff, 97px 128px #fff, 226px 634px #fff, 355px 1933px #fff, 317px 1751px #fff,
		68px 1275px #fff, 359px 766px #fff, 288px 1307px #fff, 438px 1098px #fff, 441px 1881px #fff,
		114px 1823px #fff, 282px 507px #fff, 350px 1878px #fff, 156px 1329px #fff, 571px 1690px #fff,
		368px 1231px #fff, 122px 385px #fff, 239px 1785px #fff, 344px 1978px #fff, 271px 464px #fff,
		148px 1578px #fff, 16px 41px #fff, 194px 910px #fff, 110px 1320px #fff, 498px 1239px #fff,
		427px 1405px #fff, 112px 1304px #fff, 486px 934px #fff, 505px 911px #fff, 577px 1837px #fff,
		153px 1450px #fff, 275px 1533px #fff, 117px 1957px #fff, 232px 726px #fff, 336px 817px #fff,
		378px 808px #fff, 273px 21px #fff, 430px 1074px #fff, 267px 1079px #fff, 473px 1645px #fff,
		100px 595px #fff, 567px 836px #fff, 401px 214px #fff, 329px 618px #fff, 382px 1413px #fff,
		110px 1235px #fff, 460px 1343px #fff, 420px 398px #fff, 586px 1830px #fff, 315px 341px #fff,
		68px 1651px #fff, 273px 1104px #fff, 539px 1114px #fff, 121px 1216px #fff, 468px 476px #fff,
		53px 1645px #fff, 411px 1852px #fff, 351px 126px #fff, 381px 35px #fff, 137px 1189px #fff,
		48px 1002px #fff, 327px 1832px #fff, 218px 717px #fff, 329px 679px #fff, 356px 646px #fff,
		550px 163px #fff, 100px 771px #fff, 356px 1775px #fff, 341px 1747px #fff, 40px 752px #fff,
		400px 320px #fff, 570px 1706px #fff, 118px 1401px #fff, 229px 700px #fff, 104px 1578px #fff,
		582px 171px #fff, 331px 1257px #fff, 52px 660px #fff, 209px 267px #fff, 329px 220px #fff,
		4px 521px #fff, 52px 604px #fff, 468px 1816px #fff, 210px 578px #fff, 172px 1940px #fff,
		297px 783px #fff, 202px 820px #fff, 202px 1751px #fff, 528px 1541px #fff, 375px 1249px #fff,
		562px 692px #fff, 193px 1773px #fff, 276px 16px #fff, 212px 573px #fff, 48px 329px #fff,
		217px 537px #fff, 193px 975px #fff, 80px 716px #fff, 29px 1688px #fff, 167px 1639px #fff,
		244px 521px #fff, 516px 1551px #fff, 160px 167px #fff, 584px 1248px #fff, 91px 1963px #fff,
		230px 494px #fff, 91px 1809px #fff;
}
#stars_1:after {
	content: ' ';
	position: absolute;
	top: 0px;
	width: 1px;
	height: 1px;
	background: transparent;
}

#stars_2 {
	position: absolute;
	width: 2px;
	height: 2px;
	background: transparent;
	animation: animStar 20s linear infinite;
	box-shadow: 0px 0px #fff, 37px 1240px #fff, 120px 1341px #fff, 119px 808px #fff,
		392px 776px #fff, 556px 27px #fff, 371px 195px #fff, 409px 513px #fff, 40px 1434px #fff,
		301px 1312px #fff, 188px 1981px #fff, 364px 261px #fff, 76px 515px #fff, 520px 798px #fff,
		371px 694px #fff, 574px 1959px #fff, 314px 195px #fff, 449px 376px #fff, 158px 1258px #fff,
		14px 861px #fff, 195px 599px #fff, 151px 239px #fff, 523px 1672px #fff, 515px 960px #fff,
		266px 1896px #fff, 394px 938px #fff, 226px 416px #fff, 146px 269px #fff, 166px 887px #fff,
		557px 542px #fff, 115px 374px #fff, 49px 1482px #fff, 86px 794px #fff, 217px 1571px #fff,
		319px 1438px #fff, 564px 1620px #fff, 34px 413px #fff, 109px 1241px #fff, 161px 529px #fff,
		585px 1984px #fff, 303px 1171px #fff, 379px 397px #fff, 320px 346px #fff, 156px 1868px #fff,
		527px 131px #fff, 348px 1265px #fff, 425px 1405px #fff, 252px 286px #fff, 144px 141px #fff,
		29px 913px #fff, 100px 77px #fff, 504px 920px #fff, 227px 607px #fff, 149px 830px #fff,
		64px 1923px #fff, 166px 1647px #fff, 479px 411px #fff, 249px 986px #fff, 507px 417px #fff,
		305px 1123px #fff, 590px 5px #fff, 427px 1308px #fff, 185px 666px #fff, 494px 151px #fff,
		9px 1631px #fff, 533px 1971px #fff, 480px 1412px #fff, 30px 513px #fff, 42px 152px #fff,
		132px 30px #fff, 440px 1448px #fff, 73px 1059px #fff, 41px 186px #fff, 318px 419px #fff,
		439px 1148px #fff, 139px 1595px #fff, 290px 1640px #fff, 102px 842px #fff, 450px 1237px #fff,
		42px 473px #fff, 30px 1689px #fff, 571px 1792px #fff, 586px 234px #fff, 374px 1356px #fff,
		487px 1221px #fff, 55px 95px #fff, 582px 1412px #fff, 334px 160px #fff, 327px 600px #fff,
		179px 9px #fff, 432px 1827px #fff, 596px 1388px #fff, 143px 1162px #fff, 482px 365px #fff,
		63px 1810px #fff, 124px 293px #fff, 82px 462px #fff, 593px 1856px #fff, 257px 1216px #fff,
		320px 1874px #fff, 333px 906px #fff;
}
#stars_2:after {
	content: ' ';
	position: absolute;
	top: 0px;
	width: 2px;
	height: 2px;
	background: transparent;
}

#stars_3 {
	position: absolute;
	width: 3px;
	height: 3px;
	background: transparent;
	animation: animStar 10s linear infinite;
	box-shadow: 0px 0px #fff, 336px 996px #fff, 218px 257px #fff, 331px 1232px #fff,
		486px 288px #fff, 22px 232px #fff, 312px 1076px #fff, 500px 1540px #fff, 302px 545px #fff,
		112px 1181px #fff, 347px 1798px #fff;
}
#stars_3:after {
	content: ' ';
	position: absolute;
	top: 0px;
	width: 3px;
	height: 3px;
	background: transparent;
}

.cursorPointer {
	cursor: pointer;
}

.highlighted {
	z-index: 100;
	/* background: radial-gradient(circle, rgba(50,158,81,0.18287037037037035) 0%, rgba(50,158,81,0.6157407407407407) 70%, rgba(50,158,81,1) 100%); */
	background-origin: content-box;
	/* border-radius: 2em; */
	offset-anchor: auto;
	outline-offset: 0.3rem;
	outline: 0.2rem dashed #329e51;
	/* border-color:'#329E51 #329E51 '; */
	/* box-shadow: 0 0 2em rgba(50, 158, 81, 1);   */
	pointer-events: none;
	animation: blink 1.6s ease-in-out infinite;
}

@keyframes blink {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}

@keyframes horizontal-shaking {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(2px);
	}
	50% {
		transform: translateX(-2px);
	}
	75% {
		transform: translateX(2px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes animStar {
	0% {
		transform: translateY(0px);
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		transform: translateY(100px);
		opacity: 0;
	}
}
.blink {
}
@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
/* .spaceship {
  animation: blink-animation 1s steps(5, start) infinite;
        -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation  {
        0% {
         
          filter: 'drop-shadow(0px 0 0px rgb(75 156 255 / 0.7)) drop-shadow(-0px 0 0px rgb(75 156 255 / 0.7)) drop-shadow(0 0px 0px rgb(75 156 255 / 0.7)) drop-shadow(0 -0px 0px rgb(75 156 255 / 0.7))',
        }
       
        100% {
          
          filter: 'drop-shadow(0px 0 2px rgb(75 156 255 / 0.7)) drop-shadow(-0px 0 2px rgb(75 156 255 / 0.7)) drop-shadow(0 0px 2px rgb(75 156 255 / 0.7)) drop-shadow(0 -0px 2px rgb(75 156 255 / 0.7))',
        }
      } */

@keyframes TransitioningBackground {
	0% {
		background-position: 1% 0%;
	}
	50% {
		background-position: 99% 100%;
	}
	100% {
		background-position: 1% 0%;
	}
}
@keyframes ShinyEffect {
	0% {
		transform: translateX(-100px) skewX(-15deg);
	}

	100% {
		transform: translateX(300px) skewX(-15deg);
	}
}
.spaceShipBlur {
	/* font-size: 1rem;
	 font-weight: 600;
	 color: white;
	 text-align: center;
	 width: 200px;
	 height: 60px;
	 border-radius: 5px;
	 position: relative; */
	overflow: hidden;
	/* background-image: linear-gradient(270deg, #8e9ac2, #42579a);
	 background-size: 400% 400%;*/
	transition: 2s;
}
.spaceShipBlur::before {
	content: '';
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.9);
	width: 20px;
	height: 100%;
	top: 0;
	filter: blur(30px);
	transform: translateX(-100px) skewX(-15deg);
	animation: ShinyEffect 5s infinite;
}

.spaceShipBlur::after {
	content: '';
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.9);
	width: 10px;
	height: 100%;
	top: 0;
	filter: blur(5px);
	transform: translateX(-100px) skewX(-15deg);
	animation: ShinyEffect 5s infinite;
}

.spaceShipBlur:hover::before,
.spaceShipBlur:hover::after {
	transform: translateX(300px) skewX(-15deg);
	transition: 0.7s;
	transform: scale(1.1);
}
.spaceShipBounce {
	animation-name: stretch;
	animation-duration: 1.3s;
	animation-timing-function: ease-out;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-play-state: running;
}
@keyframes stretch {
	0% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1.1);
	}
}

#mini-game-tutorial-hand {
	animation: linear 5s infinite swipe-hand;
}

#mini-game-tutorial-left-arrow {
	animation: linear 2.5 infinite swipe-left-arrow;
}

#mini-game-tutorial-right-arrow {
	animation: de linear 5s infinite swipe-right-arrow;
}

@keyframes fadeIn {
	from {
		opacity: 0.85;
	}
}

@keyframes swipe-hand {
	0% {
		transform: rotate(0deg);
		left: 40%;
		top: 60%;
		opacity: 0;
	}
	2% {
		opacity: 1;
	}
	25% {
		transform: rotate(20deg);
		left: 70%;
		opacity: 1;
		top: 63%;
	}
	30% {
		opacity: 0;
	}
	50% {
		left: 60%;
		top: 60%;
		opacity: 0;
	}
	73% {
		opacity: 2;
	}
	75% {
		left: 20%;
		transform: rotate(-20deg);
		opacity: 1;
		top: 63%;
	}
	80% {
		opacity: 0;
	}
	100% {
		transform: rotate(0deg);
		left: 50%;
		top: 60%;
		opacity: 0;
	}
}

@keyframes swipe-left-arrow {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes flickering {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.96;
	}
	100% {
		opacity: 1;
	}
}
